/**
 * @generated SignedSource<<34c655842f5fa6a40c4d101d744e3fdb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompletedCountTableCell_SettingsUI_SubscriberUploadJob$data = {
  readonly jobUploadResults: {
    readonly existingActiveOptOutSubscribersCount: number;
    readonly existingActiveSubscribersCount: number;
    readonly existingJoinedSubscribersCount: number;
    readonly invalidSubscribersCount: number;
    readonly validSubscribersCount: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CompletedCountTableCell_calculateCompletedCount_SettingsUI_SubscriberUploadJob">;
  readonly " $fragmentType": "CompletedCountTableCell_SettingsUI_SubscriberUploadJob";
};
export type CompletedCountTableCell_SettingsUI_SubscriberUploadJob$key = {
  readonly " $data"?: CompletedCountTableCell_SettingsUI_SubscriberUploadJob$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompletedCountTableCell_SettingsUI_SubscriberUploadJob">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "existingActiveOptOutSubscribersCount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "existingActiveSubscribersCount",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "existingJoinedSubscribersCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "validSubscribersCount",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompletedCountTableCell_SettingsUI_SubscriberUploadJob",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "CompletedCountTableCell_calculateCompletedCount_SettingsUI_SubscriberUploadJob",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SubscriberUploadJobResults",
          "kind": "LinkedField",
          "name": "jobUploadResults",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobType",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SubscriberUploadJobResults",
      "kind": "LinkedField",
      "name": "jobUploadResults",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invalidSubscribersCount",
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "SubscriberUploadJob",
  "abstractKey": null
};
})();

(node as any).hash = "c504c665f874ab58cc13a124aa5e6d37";

export default node;
