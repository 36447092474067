/**
 * @generated SignedSource<<de213a8751ab7920536b8647fb58aed0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type SubscriberUploadJobType = "JOB_TYPE_ADD_SUBSCRIBERS" | "JOB_TYPE_OPT_OUT_EMAILS" | "JOB_TYPE_OPT_OUT_PHONES" | "JOB_TYPE_SUBSCRIBER_TAG_ONLY" | "JOB_TYPE_SUPPRESS_SUBSCRIPTIONS" | "JOB_TYPE_UNKNOWN" | "JOB_TYPE_UNSUPPRESS_SUBSCRIPTIONS" | "JOB_TYPE_UPDATE_EXISTING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CompletedCountTableCell_calculateCompletedCount_SettingsUI_SubscriberUploadJob$data = {
  readonly jobType: SubscriberUploadJobType;
  readonly jobUploadResults: {
    readonly existingActiveOptOutSubscribersCount: number;
    readonly existingActiveSubscribersCount: number;
    readonly existingJoinedSubscribersCount: number;
    readonly validSubscribersCount: number;
  };
  readonly " $fragmentType": "CompletedCountTableCell_calculateCompletedCount_SettingsUI_SubscriberUploadJob";
};
export type CompletedCountTableCell_calculateCompletedCount_SettingsUI_SubscriberUploadJob$key = {
  readonly " $data"?: CompletedCountTableCell_calculateCompletedCount_SettingsUI_SubscriberUploadJob$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompletedCountTableCell_calculateCompletedCount_SettingsUI_SubscriberUploadJob">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "CompletedCountTableCell_calculateCompletedCount_SettingsUI_SubscriberUploadJob"
};

(node as any).hash = "6eb19e4db197c380863e178d815b8510";

export default node;
