/**
 * @generated SignedSource<<897b44e9f76723a7b8703d23dd9d3db9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubscriberUploadList_HistoryComponent_SettingsUI_Company$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useSubscriptionUploads_SubscriberUpload_SettingsUI_Company">;
  readonly " $fragmentType": "SubscriberUploadList_HistoryComponent_SettingsUI_Company";
};
export type SubscriberUploadList_HistoryComponent_SettingsUI_Company$key = {
  readonly " $data"?: SubscriberUploadList_HistoryComponent_SettingsUI_Company$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubscriberUploadList_HistoryComponent_SettingsUI_Company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "jobTypeFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubscriberUploadList_HistoryComponent_SettingsUI_Company",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "jobTypeFilter",
          "variableName": "jobTypeFilter"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useSubscriptionUploads_SubscriberUpload_SettingsUI_Company"
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "ed3cbe3c3263f07a886b486394189626";

export default node;
